







































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
	INDICATOR_FEDERAL_PASSPORT_UPDATE,
} from "@/store/actions/indicator";
import { FederalPassport } from "@/models/Indicator";

@Component({})
export default class FederalPassportForm extends Vue {
	private form: any = {};
	private loading: boolean = false;
    private menu = false;
    private menu2 = false;
    private dates = ["", ""];
	//Snackbar
	private messageText = "Не удалось выполнить";
	private messageColor = "red";
	private message = false;
	//Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];

		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;
	}
	private initSelf() {
		console.log("INIT SELF upd");
		this.initStartOptions();
		this.loading = false;
		console.log("loading");
	}
	//Methods
	private editItem() {
        // Раскомментировать при использовании Ranged календаря
        // this.form.time_series_len_from = this.dates[0];
        // this.form.time_series_len_to = this.dates[1];
		var converted = new FormData();
		Object.keys(this.form).forEach((key) => {
			console.log(key);
			converted.append(key, this.form[key]);
		});
		var data = {
			id: +this.$route.params.id,
			data: converted,
		};
		this.$store
			.dispatch(INDICATOR_FEDERAL_PASSPORT_UPDATE, data)
			.then(() => {
				this.messageText = "Федеральный паспорт изменен.";
				this.messageColor = "green";
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red";
			})
			.finally(() => {
				this.message = true;
			});
	}
	private toString(str) {
		return str as string;
	}
	private isUpdatePage() {
		return this.$route.meta.isUpdate;
	}
	private initStartOptions() {
		var item;
		if (localStorage.getItem("federal")) {
			console.log("found data in local storage");
			try {
				item = JSON.parse(this.toString(localStorage.getItem("federal")));
				console.log("item init: ", item);
			} catch (e) {
				localStorage.removeItem("federal");
			}
		} else {
			console.log("not found data in local storage");
		}
        Object.keys(item).forEach((key) => {
            this.form[key] = item[key];
        });
        delete this.form.id;
        delete this.form.indicator_id;
        let arr = this.form.time_series_len_from.split("T");
        this.form.time_series_len_from = arr[0];
        let arr2 = this.form.time_series_len_to.split("T");
        this.form.time_series_len_to = arr2[0];
        this.$forceUpdate();
        console.log("form: ", this.form);
	}
	//Hooks
	private created() {
		if(localStorage.getItem("federalIndicatorTitle")){
			let title = localStorage.getItem("federalIndicatorTitle");
			this.$router.currentRoute.meta.breadcrumb[2].text = title;
		}
  	}
	private mounted() {
		this.tryToInitSelf();
	}
	private destroyed() {
		localStorage.removeItem("federal");
	}
}
